/* Animation names should be namespaced with `panoramic-` so that we don't run
into naming conflicts with potential 3rd party libraries. */

@keyframes panoramic-fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

@keyframes panoramic-zoom-in {
  from { transform: scale(0.95) }
  to { transform: scale(1)}
}

@keyframes panoramic-pop {
  0% { transform: scale(1) }
  50% { transform: scale(1.1) }
  100% { transform: scale(1)}
}
