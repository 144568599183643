* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

html {
  font-size: 75%;
  -webkit-font-smoothing: antialiased;
  color: red; /* For debugging purposes */
}

@media (min-width: 120em) {
  html {
    font-size: 87.5%;
  }
}

body {
  margin: 0;
  background-color: #fafafa;
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

button {
  display: block;
  padding: 0;
  border: none;
  font-family: inherit;
  background-color: transparent;
  text-align: left;
  border-radius: 0;
  margin: 0;
}

input {
  display: block;
  font-family: inherit;
  border: none;
  padding: 0;
  margin: 0;
}

input::placeholder {
  color: #bac6d0;
}
